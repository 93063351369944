import React from "react";

import { graphql } from 'gatsby'
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import Header from '../components/header';
import Footer from '../components/footer';
import Meta from '../components/meta';

export const query = graphql`
  query {
    jumbotronImage: file(relativePath: { regex: "/^empress-nephrite-jade-quarry.jpg$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    slabImage: file(relativePath: { regex: "/^nephrite-jade-ornament.jpg$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    bigBlock: file(relativePath: { regex: "/^nephrite-jade-dimensional-block.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
  }
`

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { under1200: true };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    if (window.innerWidth < 1200 && !this.state.under1200) {
      this.setState({under1200: true});
    } else if (window.innerWidth > 1200 && this.state.under1200) {
      this.setState({under1200: false});
    }
  }
  
  render() {
    return(
      <div className="vh-100">
        <Meta title="About Us" />
        <Header darkFont={false}/>

        <BackgroundImage className="p-5 black-background container-fluid" fluid={this.props.data.jumbotronImage.childImageSharp.fluid} >
          <div className="row justify-content-center mt-5 pt-5">
            <div className="col-12 text-center mt-5 pt-0 pt-md-5">
              <h1 className="text-white serif mt-0  display-4 d-inline-block pt-5">About Us</h1>
              <hr className="col-1 horizontal-divider my-0"></hr>
              <p className="sans-serif text-white mt-2">Our Story</p>
            </div>
          </div>
        </BackgroundImage>

        <div className="black-background container-fluid">
          <div className="row justify-content-around pt-5 px-5 text-center text-md-left">
            <div className="col-12 col-md-6 col-lg-5 pt-0 pt-sm-5 px-0 px-md-3">
              <h4 className="serif text-white font-italic mb-4">Vancouver Jade is a world leader in resource architecture and design.</h4>
            </div>
            <div className="col-md-6 col-lg-5"></div>
          </div>
          <div className="row justify-content-around pb-5 px-5 text-center text-md-left">
            <div className="col-12 col-md-6 col-lg-5 px-0 px-md-3">
              <p className="text-white sans-serif">
                We extract, manufacture, and market nephrite jade products that encourage responsible resource development in our industry. 
                Our roots are still in mining and exploration. 
                But our journey has led us to the frontier of science and art where aesthetic blends with an intrinsic connection to the source of our stone.
              </p>
              <p className="text-white sans-serif">
                We source our material from the jade fields of Northern British Columbia, Canada. 
                We have partnered with local stakeholders and collaborated with a host of designers, 
                architects and craftsman to explore non-traditional uses of nephrite gemstone.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 px-0 px-md-3">
              <p className="text-white sans-serif">
                We believe the unique scale and inherent challenges of producing nephrite represent 
                a unique opportunity to showcase modern resource best-practices while exploring new 
                ways to connect retail markets directly to the source of their base products. 
                This approach builds in transparency and responsibility as a central working mechanism for our business.
              </p>
            </div>
          </div>
        </div>

        { this.state.under1200 ?
          <div className="black-background container-fluid">
            <div className="row justify-content-around p-5">
              <div className="col-sm-10 px-0 align-self-center">
                <Img fluid={this.props.data.slabImage.childImageSharp.fluid} alt=""/>
              </div>
              <div className="col-md-8 mt-n5 px-0 text-center text-md-left">
                <h1 className="serif text-white font-weight-lighter mb-4">OUR VISION</h1>
                <h3 className="green-accent serif">&#x275B;&#x275B;</h3>
                <p className="text-white sans-serif">
                  We have always approached the use of dimensional nephrite jade as the rediscovery of an ancient material. 
                  This creates new opportunities to push the boundaries of modern design and architecture by unlocking nephrite's incredible properties. 
                </p>
                <p className="text-white sans-serif">
                  We are a young and unconventional company, driven by a passion to explore and create. 
                  We are constantly collaborating with those who share our desire to blend art, science, innovation, and tradition to create the products and spaces of the future.
                <br></br><br></br>
                </p>
              </div>
            </div>
          </div>
          :
          <BackgroundImage className="black-background container-fluid slab-background" fluid={this.props.data.slabImage.childImageSharp.fluid}>
            <div className="row justify-content-around p-5">
              <div className="col-4 pt-5 px-0">
                <h1 className="serif text-white font-weight-lighter mb-4">OUR VISION</h1>
                <h3 className="green-accent serif">&#x275B;&#x275B;</h3>
                <p className="text-white sans-serif">
                  We have always approached the use of dimensional nephrite jade as the rediscovery of an ancient material. 
                  This creates new opportunities to push the boundaries of modern design and architecture by unlocking nephrite's incredible properties. 
                </p>
                <p className="text-white sans-serif mb-5 pb-5">
                  We are a young and unconventional company, driven by a passion to explore and create. 
                  We are constantly collaborating with those who share our desire to blend art, science, innovation, and tradition to create the products and spaces of the future.
                <br></br><br></br>
                </p>
              </div>
              <div className="col-4 px-0 align-self-center"></div>
            </div>
          </BackgroundImage>
        }

        <div className="off-white-background container-fluid">
          <div className="row justify-content-center p-5">
            <div className="col-md-8 col-lg-6 col-xl-5 px-0 text-center py-5">
              <h1 className="text-dark serif d-inline-block mb-0">OUR HISTORY</h1>
              <hr className="col-1 horizontal-divider"></hr>
              <p className="text-dark sans-serif mt-4">
                We started as explorers searching Canada's harshest landscapes for rare, elusive stone.
              </p>
              <p className="text-dark sans-serif">
                We then broke boundaries as the first Company in the world to quarry dimensional blocks of nephrite.
              </p>
              <p className="text-dark sans-serif">
                Our story continued; as pioneers of innovative manufacturing techniques, producing natural jade gemstone slabs.
              </p>
            </div>
          </div>
        </div>

        <div className="black-background container-fluid px-0">
          <Img fluid={this.props.data.bigBlock.childImageSharp.fluid} alt=""/>
        </div>

        <div className="off-white-background container-fluid">
          <div className="row justify-content-center p-5">
            <div className="col-md-8 col-lg-6 col-xl-5 px-0 text-center py-5">
              <h1 className="text-dark serif d-inline-block mb-0">OUR MISSION</h1>
              <hr className="col-1 horizontal-divider"></hr>
              <h4 className="text-dark font-italic serif mt-4">
                To design and develop innovative products that inspire thoughtfulness; of how and why we use our precious resources.
              </h4>
            </div>
          </div>
        </div>
        
        <Footer />

      </div>
    )
  }
}

export default AboutUs;